import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import Sections from '../components/Sections';
import { ContentMain, RoundedButton, device } from '../components/commonStyled';
import ProductItem from '../components/Products/ProductItem';
import { useMarket } from '../queries/useMarket';

const ProductsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -50px;
    width: 100%;
`;

const ProductItemStyled = styled(ProductItem)`
    max-width: 360px;
    flex-basis: 360px;
    min-width: 260px;
`;

const ShowMoreButton = styled(RoundedButton)`
    font-size: 18px;
    padding: 17px 45px;
    margin-bottom: 24px;
    background-color: #a60424;
    color: #ffffff;

    @media ${device.laptopSmall} {
        align-self: auto;
    }

    @media ${device.tabletMedium} {
        font-size: 16px;

        padding: 14px 24px;
    }
`;

const MAX_SHOW_ITEMS = 6;

export default ({ data, location }) => {
    const {
        title,
        seo,
        common_acf: { blocks },
    } = data?.wpPage;

    const products = useMarket();

    const [showMore, setShowMore] = useState(false);

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <ProductsContainer>
                    {products
                        .slice(0, showMore ? products.length : MAX_SHOW_ITEMS)
                        .map((item, i) => (
                            <ProductItemStyled key={i} item={item} />
                        ))}
                </ProductsContainer>
                {products.length > MAX_SHOW_ITEMS && !showMore && (
                    <ShowMoreButton onClick={() => setShowMore(true)}>
                        Больше товаров
                    </ShowMoreButton>
                )}
            </ContentMain>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: {
                        whiteStyle: true,
                        noButton: true,
                        order: 3,
                    },
                }}
            />
        </PageLayout>
    );
};

export const pageQuery = graphql`
    query MarketPage {
        wpPage(common_acf: { id: { eq: "market" } }) {
            title
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
        }
    }
`;
